import { ref } from 'vue';
import number from './formatting.number.js';
import date from './formatting.date.js';
import string from './formatting.string.js';
import data from './formatting.data.js';

let mergeOptions = function(pOutput, pDefaults, pOptions, pMergeDepth) {
    if (pMergeDepth === undef) { pMergeDepth = 1; }
    pDefaults.forEach(function (value, key) {
        if (pMergeDepth > 0 && pDefaults[key] && typeof pDefaults[key] === "object") {
            if (pDefaults[key] instanceof w.Array) { // for arrays
                if (pOptions.hasOwnProperty(key)) {
                    pOutput[key] = copyArray(pOptions[key]); // Use a value from pOptions
                } else {
                    pOutput[key] = copyArray(value); // Use the default value
                }
            } else { // for objects which are not arrays, look for matches in pOptions members
                pOutput[key] = createObject(); // initialize object
                mergeOptions(pOutput[key], value, pOptions[key] || {}, pMergeDepth - 1);
            }
        } else {
            // for other types
            if (pOptions.hasOwnProperty(key)) {
                pOutput[key] = pOptions[key]; // Use a value from pOptions
            } else {
                pOutput[key] = value; // Use the default value
            }
        }
    });
};


class Options{
    constructor(pDefinition){
        var that = this, hasRequired, hasDefault, hasPassed = ((pOption) => {return false}), hasDeepMerge, hasMergeDepth,


        errors = {
            defaultsIsReserved:
                "Invalid option key 'defaults'. This is reserved for keeping the default values.",
            unnecessaryDefault:
                "Default value for '{0}' is not necessary as this is marked as required.",
            missingRequired:
                "Missing required option '{0}'!",
            someRequiredButNonePassed:
                "Some options are required, but no options was passed to the constructor!",
            deepMergeMustBeObject:
                "Default and passed value for option marked for deep-merge '{0}' must be of type object."
        };
    if (pDefinition && pDefinition.hasOwnProperty("required") && pDefinition.required instanceof Array) {
        hasRequired = function (key) { return (pDefinition.required.indexOf(key) !== -1); };
    }
    if (pDefinition && pDefinition.hasOwnProperty("defaults") && pDefinition.defaults && typeof pDefinition.defaults === "object") {
        hasDefault = function (key) { return pDefinition.defaults.hasOwnProperty(key)  };
    }
    if (pDefinition && pDefinition.hasOwnProperty("passed") && pDefinition.passed && typeof pDefinition.passed === "object") {
        hasPassed = function (key) { return pDefinition.passed.hasOwnProperty(key)};
    }
    if (pDefinition && pDefinition.hasOwnProperty("deepMerge") && pDefinition.deepMerge instanceof Array) {
        hasDeepMerge = function (key) { return (pDefinition.deepMerge.indexOf(key) !== -1); };
    }
    if (pDefinition && pDefinition.hasOwnProperty("mergeDepth") && typeof pDefinition.mergeDepth === "number") {
        hasMergeDepth = pDefinition.mergeDepth;
    }
    hasMergeDepth = 1;
    // First we set all the required options
    if (hasRequired) {
        if (hasPassed) {
            pDefinition.required.forEach(function (optionName, i) {
                if (optionName === "defaults") {
                    throw new Error(errors.defaultsIsReserved);
                    
                } else {
                    // If has defaults, and a required option has a default value and console.warn is present
                    if (hasDefault && hasDefault(optionName)) {
                        console.warn(string.formatString(errors.unnecessaryDefault, optionName));
                    }
                    if (hasPassed(optionName)) { // If the required option was passed
                        that[optionName] = pDefinition.passed[optionName];
                    } else {
                        throw new Error(string.formatString(errors.missingRequired, optionName));
                    }
                }
            });
        } else {
            throw new Error(errors.someRequiredButNonePassed);
        }
    }
    // Then we set apply all options specified in defaults, falling back to the default value if not specified
    if (hasDefault) {
        if (hasDefault("defaults")) { // Deny usage of 'defaults' as key, as this is reserved
            throw new Error(errors.defaultsIsReserved);
        }
        pDefinition.defaults.forEach((defaultValue)=> {
            if (typeof defaultValue === "object" ){
                Object.keys(defaultValue).forEach(optionName=>{
                    if(hasPassed(optionName)) {
                       that[optionName] = pDefinition.passed[optionName]
                    }else{
                         that[optionName] = defaultValue[optionName];
                    };
                        
                })
            }else{
                throw new TypeError(string.formatString(errors.deepMergeMustBeObject, optionName));
            }
        });

        that.defaults = new Defaults(pDefinition.defaults); // keep dictionary with the default values
    }
    }
};
var Defaults = function(pDefaults) {
    Object.values(pDefaults).forEach(x=>{
        Object.keys(x).forEach(y=>this[y] = x[y])
        
    });
};
var extend = function(obj) {
    var i, a = arguments, l = a.length;
    function extendObj(key, value) { obj[key] = value; }
    for (i = 1; i < l; i++) { forEach(a[i], extendObj); }
    return obj;
};

var forEach = function(pObj, pFunc) {
    var i;
    if (pObj instanceof Array) {
        for (i = 0; i < pObj.length; i++) {
            if (pFunc.call(pObj, i, pObj[i]) === false) { return false; }
        }
    } else {
        for (i in pObj) {
            if (pObj.hasOwnProperty(i)) {
                if (pFunc.call(pObj, i, pObj[i]) === false) { return false; }
            }
        }
    }
};

/**
 * Options used in setPosition
 * @typedef     {Object} SetPositionOptions
 * @property    {number} [gap=0]
 *              Gap is the number of pixels that should be between main element and sub element on the y-axis
 * @property    {boolean} [rePositionOnResize=true]
 *              Decides if the method should listen to window resize events and trigger a position recalculation
 * @property    {boolean} [rePositionOnScroll=true]
 *              Decides if the method should listen to document scroll events and trigger a position recalculation
 */

/**
 * Method that will reposition subElement close to mainElement.
 * @param   {HTMLElement} mainElement
 *          Main element is the element that will used to figure out a new position for the sub element.
 * @param   {HTMLElement} subElement
 *          Sub element is the element that will be repositioned once initially and then on resize or scroll events if set.
 * @param   {SetPositionOptions} [options]
 *          Contains different options that will be used during position calculation and when setting up events.
 * @returns {(AbortController|undefined)}
 *          If resize or scroll events should be listened to, a AbortController is returned.
 *          This should be used when there is no longer a need to recalculate the position on scroll or resize changes.
 */
var setPosition = function(mainElement, subElement, options = {}) {
    if (!(mainElement instanceof HTMLElement)) {
        throw Error('Main element must be a HTMLElement');
    }

    if (!(subElement instanceof HTMLElement)) {
        throw Error('Sub element must be a HTMLElement');
    }

    let gap = Number(options.gap),
        rePositionOnResize = options.rePositionOnResize,
        rePositionOnScroll = options.rePositionOnScroll,
        abortController = new AbortController();

    if (isNaN(gap) || gap < 0) {
        gap = 0;
    }

    if (typeof(rePositionOnResize) !== "boolean") {
        rePositionOnResize = true;
    }

    if (typeof(rePositionOnScroll) !== "boolean") {
        rePositionOnScroll = true;
    }

    let render = () => {
        window.requestAnimationFrame(function() {
            let mainElementRect = mainElement.getBoundingClientRect(),
                subElementRect = subElement.getBoundingClientRect(),
                scrollX = window.scrollX,
                scrollY = window.scrollY,
                top = undefined,
                left = undefined;

            if (mainElementRect.bottom + subElementRect.height + gap < window.innerHeight) {
                top = mainElementRect.bottom + scrollY + gap;
            } else {
                top = mainElementRect.top + scrollY - subElementRect.height - gap;
            }

            left = mainElementRect.left + scrollX;

            if (mainElementRect.left > 0 && mainElementRect.left < mainElementRect.width) {
                left = 0;
            } else if (mainElementRect.left < window.innerWidth && mainElementRect.left > window.innerWidth - subElementRect.width) {
                left = window.innerWidth - subElementRect.width + scrollX;
            }

            subElement.style.position = 'absolute';
            subElement.style.top = `${top}px`;
            subElement.style.left = `${left}px`;
        });
    };

    if (rePositionOnResize) {
        window.addEventListener('resize', render, { signal: abortController.signal });
    }

    if (rePositionOnScroll) {
        document.addEventListener('scroll', render, { signal: abortController.signal });
    }

    render();

    if (rePositionOnResize || rePositionOnScroll) {
        return abortController;
    }

    return undefined;
};

const formatDate = function(pDate, pFormat, utc) {
    if(!pDate) return null;

    let vDate = null;
    if(pDate.constructor === Date){
        vDate = pDate;
    }else{
        vDate = new Date(pDate);
    }

    if (vDate.toString() === "Invalid Date") {
        return null;
    }
    return date.format(vDate,pFormat, utc);
}

const formatNumber = (pNumber, pFormat) => {
    if (pNumber == null || !pFormat) { return pNumber; }

    if (typeof pNumber !== 'number') {
        pNumber = parseFloat(pNumber);
    }

    return number.format(pNumber, pFormat);
}

const format = function(pValue,pColumn){
     if(['date','datetime','time'].indexOf(pColumn?.type) > -1){
        return formatDate(pValue,pColumn.format,pColumn.utc);
    } else if (pColumn?.type === 'number') {
        return formatNumber(pValue, pColumn.format);
    } else{
        return pValue;
    }
}

function getValidDate(date) {
    if (!date) {
        return null;
    }
    date = new Date(date);
    if (isNaN(date)) {
        return null;
    }
    return date;
}

function formatFileSize(size) {
    return number.formatFileSize(size);
}

function formatForCopyValue(value, colType) {
    let returnValue;
    if (value === null) {
        return "";
    }
    switch (colType) {
        case "time":
            returnValue = formatDate(value, "HH:mm");
            break;
        case "number":
            returnValue = parseFloat(value);
            break;
        case "datetime":
            returnValue = formatDate(value, "yyyy-MM-dd HH:mm:ss");
            break;
        case "boolean":
            if (value === "USANN" || value === "FALSE" || !value || value === 0 || value === "") {
                returnValue = 0;
            } else {
                returnValue = 1;
            }
            break;
        case "date":
            returnValue = formatDate(value, "yyyy-MM-dd");
            break;
        case 'bit':
            returnValue = `${value}`;
            break;
        default:
            returnValue = value;
            // returnValue = value.replace(/\n/g, '').replace(/\r/g, '');
            break;
    }

    return returnValue !== null ? returnValue : "";
}

function ExternalPromise() {
    let resolve;
    let reject;
    const promise = new Promise((resolve2, reject2) => {
        resolve = resolve2;
        reject = reject2;
    });
    return [ promise, resolve, reject ];
}

export default { date, number, Options, mergeOptions, extend, data, string, setPosition,format, formatDate, formatNumber, getValidDate, formatFileSize, formatForCopyValue, ExternalPromise };

